import Lottie from 'lottie-web'
import debounce from 'lodash/debounce'
import PropTypes from 'prop-types'
import React, { useEffect, useState, useRef } from 'react'
import tw, { css, theme } from 'twin.macro'
import { global } from '../styles/global'
import { max, min } from '../utils/breakpoints'
import { LinkType, StyleType, SVGType } from '../utils/prop-types'
import Button from './button'
import Image from './image'
import Link from './link'
import MainNav from './main-nav'
import Text from './text'
import LanguagePicker from './language-picker'


const MarketSelector = ({ link, style }) => (
  <div
    css={[
      css`
        ${tw`relative h-10`}
        &:hover {
          button + div {
            ${tw`opacity-100 pointer-events-auto`}
          }
          svg {
            ${tw`transform -rotate-180`}
          }
        }
      `,
      style,
    ]}
  >
    <button type="button" css={[tw`flex items-center h-10 bg-white whitespace-nowrap`, global`base.hover-underline`()]}>
      <Text content={link.label} textType="subtitle" style={tw`font-medium ml-3.5`} />
      <Image image={link.navigation[0].image} style={tw`ml-2 mr-7 py-3 xl:mr-3.5`} />
    </button>
    {link.navigation[0].subLinks && link.navigation[0].subLinks.length ? (
      <div
        css={tw`absolute px-6 py-6 mr-6 bg-white w-77.5 -right-8 top-20 opacity-0 transition-opacity duration-300 ease-in-out`}
      >
        <div css={tw`flex flex-col`}>
          <Text
            textType="subtitle"
            content="Other languages / markets"
            style={tw`font-medium mb-4`}
          />
          <div
            css={tw`flex flex-col mt-16 lg:(grid grid-cols-2 gap-x-2 mt-0)`}
          >
            {link.navigation[0].subLinks.map((subLink) => (
              <div key={subLink.label} css={tw`flex flex-col items-start mt-2`}>
                <Link to={subLink.link} style={tw`border border-primary-300 w-full rounded-md py-6`}>
                  <Text content={subLink.label} textType="subtitle" style={tw`font-bold px-4`} />
                  <Text content={subLink.description} textType="body-xs" style={tw`font-light px-4`} />
                </Link>
              </div>
            ))}
          </div>
          <div css={tw`flex flex-col items-start my-4`}>
            <div
              css={[
                tw`absolute inset-x-0 bottom-0 block h-px lg:(bg-line-grey)`,

              ]}
            />
          </div>
        </div>
      </div>
    ) : undefined}
  </div>
)

MarketSelector.propTypes = {
  link: LinkType,
  style: StyleType,
}

const Header = ({
  logoWhiteAccent,
  logoCyanAccent,
  callToAction,
  topNavItems,
  navItems,
  socialMediaLabel,
  socialMedia,
  copyright,
  customerArea,
  marketSelector,
  withNav,
  headerTransition,
  headerTransitionColor,
}) => {
  const [isOpened, setIsOpened] = useState(false)
  const [isFirstOptionMenu, setIsFirstOptionMenu] = useState(true)
  const [detectScroll, setDetectScroll] = useState(false)
  const [detectHover, setDetectHover] = useState(false)
  const logoDiv = useRef()
  const handleHover = (hover) => setDetectHover(hover)
  const handleScroll = debounce(() => {
    if (window.pageYOffset === 0) {
      setDetectScroll(false)
    } else {
      setDetectScroll(true)
    }
  }, 50)

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })
    return () => {
      window.removeEventListener('scroll', handleScroll, { passive: true })
    }
  }, [])

  const loadAnimation = (json) => {
    return Lottie.loadAnimation({
      container: logoDiv.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: json,
      rendererSettings: {
        preserveAspectRatio: 'xMinYMax meet',
      },
    })
  }

  useEffect(() => {
    const displayWhiteLogo =
      !detectScroll &&
      !detectHover &&
      headerTransition &&
      headerTransitionColor === 'white' &&
      !isOpened

    const isMobile =
      parseFloat(document.documentElement.style.getPropertyValue('--vw').slice(0, -2)) < 10.24

    const selectedLogo = (() => {
      if (displayWhiteLogo) return logoWhiteAccent
      if (isMobile && isOpened) return logoWhiteAccent
      return logoCyanAccent
    })()

    setIsFirstOptionMenu(withNav)

  const animation = loadAnimation(selectedLogo)
    return () => {
      animation.destroy()
    }
  }, [detectScroll, detectHover, headerTransition, headerTransitionColor, isOpened])


  return (
    <div>
      <header
        onMouseEnter={() => handleHover(true)}
        onMouseLeave={() => handleHover(false)}
        css={[
          css`
          ${tw`fixed inset-x-0 top-0 z-20 transition-all duration-300 ease-in-out bg-transparent h-14`}
          ${max.lg} {
            ${theme`colors.white`}
            background-size: 200% 100%;
            background-position: left bottom;
          }
          ${min.lg} {
            ${tw`h-14`}
            &:hover {
              ${tw`bg-white`}
            }
          }
        `,
          headerTransition && !detectScroll && !detectHover ? tw`bg-grey-light` : tw`bg-white`,
          isOpened &&
          css`
            ${max.lg} {
              ${tw`bg-right-bottom`}
            }
          `,
        ]}
      >
        <div css={[global`layout.container`, global`layout.grid`, tw`items-center h-full`]}>
          <div css={[tw`flex flex-row`]}>
            <Link to={topNavItems[0].link.link} style={tw`whitespace-nowrap`} onClick={() => setIsOpened(false)}>
              <Text content={topNavItems[0].label} textType="subtitle" css={[tw`font-light`, isFirstOptionMenu && tw`font-bold`]} />
            </Link>
            <Link to={topNavItems[1].link.link} style={tw`whitespace-nowrap ml-4 sm:ml-10 `} onClick={() => setIsOpened(false)}>
              <Text content={topNavItems[1].label} textType="subtitle" css={[tw`font-light`, !isFirstOptionMenu && tw`font-bold`]} />
            </Link>
          </div>

          <div
            css={[
              tw`flex justify-end items-center h-full col-start-4 md:col-start-8 lg:(col-start-12 col-end-13)
              xl:(col-start-12 col-end-17)`,
            ]}>
            <Link to={customerArea.link} 
              css={[
                tw`col-span-1 ml-11 whitespace-nowrap`,
              ]}
              onClick={() => setIsOpened(false)}>
              <Text content={customerArea.label} textType="subtitle" style={[tw`font-light`,!isFirstOptionMenu && tw`font-medium`]} />
            </Link>
          </div>
        </div>
        <div
          css={[
            tw`absolute inset-x-0 bottom-0 block h-px -z-10 lg:(hover:bg-line-grey)`,
            isOpened && tw`hidden lg:(bg-secondary-500)`,
            detectHover && tw`bg-line-grey`,
            detectScroll && tw`bg-line-grey`,
          ]}
        />
      </header>
      <header
        onMouseEnter={() => handleHover(true)}
        onMouseLeave={() => handleHover(false)}
        css={[
          css`
        ${tw`fixed inset-x-0 top-14 z-20 transition-all duration-300 ease-in-out bg-transparent h-header-mobile`}
        ${max.lg} {
          background: linear-gradient(
            to left,
            ${theme`colors.secondary.500`} 50%,
            ${headerTransition && !detectScroll && !detectHover ? 'transparent 50%' : 'white 50%'}
          );
          background-size: 200% 100%;
          background-position: left bottom;
        }
        ${min.lg} {
          ${tw`h-header-default`}
          &:hover {
            ${tw`bg-white`}
          }
        }
      `,
          headerTransition && !detectScroll && !detectHover ? tw`bg-transparent` : tw`bg-white`,
          isOpened &&
          css`
          ${max.lg} {
            ${tw`bg-right-bottom`}
          }
        `,
        ]}
      >
        <div css={[global`layout.container`, global`layout.grid`, tw`items-center h-full`]}>
          <Link to="/" style={tw`col-span-2`} onClick={() => setIsOpened(false)}>
            <div ref={logoDiv} css={tw`h-logo-mobile lg:h-logo-default`} />
          </Link>
          <div
            css={[tw`col-start-4 col-end-5 
                md:(col-start-8 col-end-9) 
                lg:(col-start-3 col-end-10 h-full px-4) 
                xl:(col-start-4 col-end-12 px-0)`, 
                !isFirstOptionMenu && tw`hidden`]}>
            <MainNav
              navItems={navItems}
              socialMediaLabel={socialMediaLabel}
              socialMedia={socialMedia}
              callToAction={callToAction}
              copyright={copyright}
              isOpened={isOpened}
              setIsOpened={setIsOpened}
              handleHover={handleHover}
              headerTransition={headerTransition}
              headerTransitionColor={headerTransitionColor}
            />
          </div>
          <div
            css={[
              tw`hidden lg:(flex justify-end items-center h-full col-start-12 col-end-13)
          xl:(col-start-12 col-end-17)`,
            ]}
          >
            <Button
              type="primary"
              size="xs"
              label={callToAction.label}
              link={callToAction.link}
              theme="navy-cyan"
              css={[
                tw`hidden whitespace-nowrap lg:hidden`,
                isFirstOptionMenu && tw`hidden whitespace-nowrap lg:block`
              ]}
              hideCaret
            />
            <LanguagePicker style={[tw`ml-4`]} url="https://bolttech.io"/>
            {/* <MarketSelector link={marketSelector[0]} style={tw`lg:(block ml-7)`} /> */}
          </div>
        </div>
        <div
          css={[
            tw`absolute inset-x-0 bottom-0 block h-px -z-10 lg:(hover:bg-line-grey)`,
            isOpened && tw`hidden lg:(bg-secondary-500)`,
            detectHover && tw`bg-line-grey`,
            detectScroll && tw`bg-line-grey`,
          ]}
        />
      </header>
    </div>
  )
}

Header.propTypes = {
  logo: SVGType,
  callToAction: LinkType,
  navItems: PropTypes.arrayOf(PropTypes.object),
  socialMedia: PropTypes.arrayOf(PropTypes.object),
  copyright: PropTypes.string,
  customerArea: LinkType,
  headerTransition: PropTypes.bool,
  withNav: PropTypes.bool,
  headerTransitionColor: PropTypes.oneOf(['white', 'navy']),
}

Header.defaultProps = {
  withNav: false,
}

export default Header
